.loader {
    width: calc(90%);
    margin: auto;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loader_icon {
    color: gray;
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
    mix-blend-mode: multiply;
  }