.container {
    display: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #082023;
  }
  .blur {
    cursor: not-allowed;
  }
  
  .login_box {
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 20px;
    height: 80%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
  }
  
  .logo {
    text-align: left;
  }
  
  .logo img {
    width: 150px;
    height: 50px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 40px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 350px;
  }
  .form label {
    margin-bottom: 8px;
    color: white;
  }
  .form h2 {
    margin-bottom: 15px;
    color: white;
  }
  .form input[type="text"],
  .form input[type="password"],
  .form select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid white;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: transparent;
    color: white;
    outline: none;
  }
  
  .show_password {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .show_password input[type="checkbox"] {
    margin-right: 10px;
  }
  .show_password label {
    margin-top: 5px;
  }
  
  .show_password a {
    text-align: right;
    color: white;
    text-decoration: none;
    font-size: 14px;
    padding: 10px;
    margin-left: 89px;
  }
  .form a:hover {
    color: white;
  }
  .login_button {
    width: 100%;
    padding: 10px;
    background-color: #cf944f;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .image {
    text-align: right;
  }
  
  .image img {
    width: 700px;
    height: 550px;
  }
  @media screen and (min-width: 768px) and (max-width: 950px) {
    .image img {
      width: 400px;
      height: 350px;
    }
  }
  @media screen and (min-width: 500px) and (max-width: 768px) {
    .image img {
      width: 300px;
      height: 250px;
    }
  }
  @media screen and (max-width: 500px) {
    .image {
      display: none;
    }
  }
  