.company_header {
  padding: 20px;
}

.company_table {
  margin-top: 20px;
}

.approvedBtn {
  background: rgb(207, 148, 79);
  width: fit-content;
  margin: auto;
  padding: 10px;
  border-radius: 52px;
  color: white;
  cursor: pointer;
}

.centerVertical {
  vertical-align: middle;
}
.company_table table {
  border-collapse: collapse;
  width: 100%;
}

.company_table th,
.company_table td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
  text-align: center;
}


.paginations {
  display: flex;
  justify-content: space-between;
}

.previous {
  background: rgb(207, 148, 79);
  color: white;
  padding: 10px;
  width: 137px;
  border-radius: 41px;
  border: none;
}

.next {
  background: rgb(207, 148, 79);
  color: white;
  padding: 10px;
  width: 137px;
  border-radius: 41px;
  border: none;
}

.previous:focus, .next:focus {
  outline: none;
}

.dropdownBtn {
  background: white;
  color: black;
  margin: auto;
  border: 0.1px solid rgb(207, 148, 79);
}

.dropdownBtn:hover {
  background: white;
  color: black;
}

.centeredDropdown {
  display: flex;
  justify-content: center;
}

.dropdownHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}