.create_company_btn {
  display: flex;
  justify-content: flex-end;

}

.btn-close {
  background: transparent !important;
  border: none !important;
  font-size: 21px !important;
}

.accordion-button {
  background: none;
  width: 100%;
  text-align: left;
  padding-left: 10px;
  border: none;
  border-bottom: 0.2px solid;
  padding-bottom: 7px;
}

.showOn {
  background-color: rgb(60, 60, 255);
  border: 5px;
}

.showOff {
  background-color: rgb(103, 103, 104);
  border: 5px;
}

.btn {
  width: 145px;
  height: 44px;
  padding: 5px;
  border-radius: 37px;
  color: white;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}