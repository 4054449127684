.guidanceHeader{
    display: flex;
    justify-content: flex-start;
}

.toggleGuidance{
    background: white;
    color: black;
    border: 1px solid;
    border-radius: 34px;
    padding: 12px 26px;
    margin-right: 52px;
}
.active{
    color: white;
    background: #CF944F;
    border: none;
    border-radius: 34px;
    padding: 12px 26px;
    margin-right: 52px;
}

.mainComp{
    margin: 90px 52px 52px 52px;
}

.guidance{
    padding: 100px;
}
.sectionContent{
    margin-top: 16px;
    width: fit-content;
    margin-bottom: 65px;
}

.buttonandText{
    display: flex;
    justify-content: space-between;
    width: 900px;
}

.videoLink{
    height: fit-content;
    margin-top: 22px;
    background: none;
    padding: 8px;
    width: 114px;
    border-radius: 10px;
    border: 0.5px solid #959595;
    color: blue;
    font-weight: bold;
    outline: none;
}
.helpTextStatus{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.helpContent{
    padding: 100px;
}
.helpContent button{
    height: fit-content;
    margin-left: 35px;
    background: none;
    padding: 8px;
    width: 133px;
    border-radius: 10px;
    border: 0.5px solid #959595;
    font-weight: bold;
    outline: none;
}
.pagination{
    display: flex;
    justify-content: center;
}
.nextpaginationButton {
    cursor: pointer;
    margin-left: 40px;
    font-size: 23px;
    color: white;
  background: #5151ff;
  padding: 11px;
  border-radius: 6px;
  }
.previouspaginationButton {
  cursor: pointer;
  margin-right: 40px;
  font-size: 23px;
  color: white;
  background: #5151ff;
  padding: 11px;
  border-radius: 6px;
  }
  
  .paginationButton[disabled] {
    opacity: 0.5;
    font-size: 23px;
    margin-right: 40px;
  }
  
  .helpFooter{
    display: flex;
    justify-content: space-between;
    width: 440px;
  }
  .helpFooter a{
    text-decoration: underline;
    cursor: pointer;
    color: black;
  }
  .totalHelp{
    display: flex;
    justify-content: space-between;
  }
  .addQuery button{
    color: white;
    background: #CF944F;
    border: none;
    border-radius: 34px;
    padding: 12px 26px;
    margin-right: 52px;
    outline: none;
  }
  .addQueryDiv{
    background: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    width: 490px;
    height: fit-content;
    position: absolute;
    left: 32%;
    padding: 20px 33px;
    border: 2px solid;
    border-radius: 6px;
  }
  .titleInput{
    border: none;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 24px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .descInput{
    border: none;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 24px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 140px;
}
  .titleLable, .descLabel, .addFile{
    font-size: 20px;
  }
  .addFileDesc{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 100%;
    height: 140px;
    margin: auto;
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  .addFileInput {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.icon {
    position: absolute;
    right: 46%;
    top: 32%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 38px;
}
.fileText {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: #333;
}
.save{
    display: flex;
    justify-content: center;
    margin: 26px;
}
.saveBtn{
    background: #CF944F;
    color: white;
    width: 81px;
    padding-top: 4px;
    padding-bottom: 5px;
    border: none;
    border-radius: 5px;
    font-size: 21px;
    cursor: pointer;
    outline: none;
}
.answer_table th, .answer_table td {
  border: 1px solid #ffffff;
  padding: 8px;
  width: 187px !important;
  text-align: center;
} 

.answer_table {
  width: 100%;
  table-layout: auto; /* Columns adjust their width based on content */
  border-collapse: collapse;
  margin-top: 30px;
}

/* Define the styles for table header cells */
.answer_table th {
  border: 1px solid #CF944F;
    padding: 8px;
    text-align: center;
    background-color: #CF944F;
}
.answer_table td {
  cursor: pointer;
}

.custom_datepicker_input{
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
 }