.sidebar {
  position: fixed;
  top: 50px;
  left: 0px;
  height: 100%;
  background-color: #141111;
  z-index: 10;
  border-right: 1px solid #bbb;
}

.sidebaropen_icon {
  margin-left: 45px;
  margin-top: 3px;
  width: 28px;
  height: 28px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 13px;
  color: black;
  margin-bottom: 5px;
}

.sidebaropenicon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: transparent;
  cursor: pointer;
  padding: 2px;
  margin-right: 5px;
  margin-top: 12px;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 0px;
  top: 4px;
  color: black;
  font-size: 20px;
  z-index: 1;
}

.sidebaropenicon:hover {
  color: #172b4d;
}

.sidebaropen_icon:hover {
  background-color: rgba(164, 161, 161, 0.313);
}

.sidebarclose_icon {
  position: absolute;
  top: 13px;
  left: 10px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  color: black;
}

.sidebaricon_onisPath {
  margin-left: 17px;
  cursor: pointer;
}

.sidebarclose_icon:hover {
  transition: left 0.5s ease;
  background-color: rgba(164, 161, 161, 0.313);
}

.sidebarcaret_icon {
  font-size: 20px;
  margin-left: 15px;
}

.sidebarcaretclose_icon {
  font-size: 20px;
  margin-left: 15px;
}

.sidebar.open {
  left: 0px;
  width: 250px;
  transition: 100ms ease;
}

.sidebar.closed {
  width: 53px;
  transition: 0.3s ease-in-out;
}

.sidebarcloses_icon {
  font-size: 20px;
  margin-left: 15px;
}

.sidebar_ul {
  padding: 0px;
  overflow-y: scroll;
  margin-top: 25px;
  height: 387px;
}

.sidebar_ul::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.submenu_first_navlink {
  text-decoration: none;
  color: white;
  margin-left: 70px;
}

.board_navlink {
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

.blog_tag_list {
  margin-left: 35px;
  list-style-type: none;
  box-shadow: none;
  text-decoration: none;
}

.heading_tag_list {
  list-style-type: none;
  box-shadow: none;
  text-decoration: none;
}

.board_navlink:hover {
  text-decoration: none;
}

.sidebar_li {
  padding: 6px 0px;
}

.sidebar_li:hover {
  background-color: rgba(214, 216, 216, 0.457);
}

.active_navlink {
  background-color: hsla(0, 0%, 0%, 0.16);
  padding: 6px 0px;
  color: #172b4d;
}

.sidebar_heading {
  display: flex;
  align-items: center;
  margin-left: 17px;
  font-size: 20px;
  position: relative;
  gap: 15px;
  font-size: 1rem;
  font-weight: 500;
  color: #CF944F !important;
  padding: 6px;
  margin-top: 10px;
}

.heading_boardPage {
  font-size: 17px;
  margin-left: 0px;
  font-size: 17px;
  margin-left: 0px;
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 2px;
}

.sidebar_icon {
  font-size: 17px;
  margin-left: -8px;
}

@media (max-width: 468px) {
  .sidebar_heading {
    font-size: 15px;
    position: relative;
  }

  .sidebar {
    position: fixed;
    top: 50px;
    left: 0px;
    height: 100%;
    background-color: lightgray;
    transition: 0.6s ease-in-out;
    z-index: 10;
    box-shadow: 0.1px 0.1px #edebeb;
  }

  .sidebar.open {
    left: 0px;
    width: 250px;
    transition: 0.6s step-start;
  }
}

.user_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  position: fixed;
  left: 30px
}

.user_info_close {
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  position: fixed;
  left: 10px;
}

.user_details {
  display: flex;
  align-items: center;
  border-bottom: 1px solid;
}

.user_details_close {
  border-bottom: 1px solid;
}

.logout_details {
  display: flex;
  align-items: center;
  margin-left: -55px;
}

.logout_details p {
  margin-top: 15px;
  margin-left: 25px;
  font-size: 18px;
  color: #CF944F;
}

.user_icon {
  font-size: 40px;
  margin-right: 10px;
}

.user_icon_close {
  margin-bottom: 5px;
  width: 35px;
  height: 35px
}

.user_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
}

.username {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #CF944F;
}

.user_email {
  font-size: 14px;
  display: flex;
  color: #CF944F;
  flex-wrap: wrap;
  width: 147px;
  max-width: 147px;
  overflow-wrap: anywhere;
}

.logout_icon_close {
  margin-left: 50px;
  margin-bottom: 20px;
  margin-top: 10px
}

.logout_text {
  font-size: 14px;
  color: #999;
  cursor: pointer;
}

.logout_text:hover {
  color: #CF944F;
}

@media (min-width: 767px) and (max-width: 800px) {
  .sidebar_heading {
    display: block;
    font-size: 20px;
    position: relative;
  }


  .sidebar {
    position: fixed;
    top: 50px;
    left: 0px;
    height: 100%;
    background-color: #141111;
    transition: transform 0.3s ease-in-out;
    z-index: 10;
    box-shadow: 0.1px 0.1px #edebeb;
  }

}


@media (min-width: 768px) and (max-width: 1024px) {
  .sidebar_heading {
    display: block;
    font-size: 20px;
    position: relative;
  }



  .sidebar {
    position: fixed;
    top: 50px;
    left: 0px;
    height: 100%;
    background-color: #141111;
    transition: transform 0.3s ease-in-out;
    z-index: 10;
    box-shadow: 0.1px 0.1px #edebeb;
  }
}

@media screen and (max-width: 500px) {
  .sidebar.closed {
    width: 12px;
  }

  .sidebarcaretclose_icon {
    display: none;
  }

  .sidebaricon_onisPath {
    border-radius: 50%;
    height: 24px;
    width: 24px;
    margin: 15px 0 0 2px;
    display: flex;
    background-color: #00000029;
    justify-content: center;
    align-items: center;
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    border: 1px solid var(--dynamic-text-transparent, #dfe1e6);
  }

  .sidebarcaret_icons {
    margin-left: 0;
    font-size: inherit;
  }

}