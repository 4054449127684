.main_header {
  box-shadow: 1px 0.1px #edebeb;
  height: 70px;
  z-index: 1000;
  background-color: #0079bf;
  width: 100%;
  padding: 10px;
}
.navlink {
  display: flex;
  text-decoration: none;
}

.nav_user_details {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 40px;
}

.navbar_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
}
.btn-transparent {
  background-color: transparent;
  color: white;
}
.mela_logo {
  width: 180px;
  height: 100px;
  color: white;
}
.small_mela_logo {
  width: 50px;
  height: 50px;
  color: white;
}

.deployer_heading {
  margin-top: 20px;
  margin-left: -16px;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
}

.btn_design {
  color: #CF944F;
  background-color: transparent;
}

.company_heading {
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
}

@media only screen and (max-width: 480px) {
  .mela_logo {
    width: 155px;
    height: 100px;
    margin-top: -3px;
  }

  .deployer_heading {
    font-size: 16px;
    font-weight: 700;
    margin-top: 7px;
    margin-left: -10px;
  }
  .company_heading {
    margin-top: 34px;
    margin-left: 50px;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
  }
  .nav_user_details {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-right: 40px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
  .mela_logo {
    width: 155px;
    height: 100px;
    margin-top: -5px;
  }

  .deployer_heading {
    margin-top: 8px;
    margin-left: -10px;
    font-size: 16px;
    font-weight: 700;
  }
}
