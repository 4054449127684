.app {
  flex-direction: column;
  height: 100vh;
  bottom: 0;
  left: 0px;
  right: 0;
  top: 0;
  position: absolute;
}

.sidebar_open {
  margin-left: 250px;
  transition: 0.3s ease-in-out;
}

.sidebar_close {
  margin-left: 25px;
  transition: 0.3s ease-in-out;
}
.main_content {
  flex-grow: 1;
  padding: 0px;
  margin-top: 70px;
  height: 87vh;
  margin-left: 0px;
}

.main_content2 {
  flex-grow: 1;
  padding: 0px;
  margin-top: 53px;
  height: 73vh;
  margin-left: 0px;
}

@media only screen and (max-device-width: 890px) {
  .main_content {
    height: 82vh;
  }
}
