.sectionDropdown{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.mainLogs{
    padding: 20px;
}
.sectionBtn{
    background: rgb(207, 148, 79);
}
.sectionBtn .show{
    background: rgb(207, 148, 79) !important;
}
.sectionBtn:hover{
    background: rgb(207, 148, 79);
}
.centerVertical {
    vertical-align: middle;
  }